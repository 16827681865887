import React from "react";
import FlexBox from "./styledComponents/FlexBox";
import { EditButton } from "./EditButton";
import DeleteButton from "./DeleteButton";
import ViewButton from "./ViewButton";

export default function BaseActionBox({
  state,
  index,
  data,
  icon,
  onClick,
  editProps,
  deleteProps,
  viewProps,
  hasEdit,
  hasDelete,
  hasView,
  childrenLocation,
  children,
  endChildren,
  flexBoxProps = {},
}) {
  return (
    <FlexBox {...flexBoxProps}>
      {childrenLocation == "start" ? children : null}
      {hasView ? (
        <ViewButton
          data={data}
          icon={icon}
          index={index}
          onClick={onClick}
          state={state}
          {...viewProps}
        />
      ) : null}
      {hasEdit ? (
        <EditButton
          data={data}
          icon={icon}
          index={index}
          disabled={editProps?.disabled ? editProps?.disabled : false}
          onClick={onClick}
          state={state}
          styles={{ bg: "#575757", color: "white" }}
          {...editProps}
          sx
        />
      ) : null}
      {hasDelete ? (
        <DeleteButton index={index} data={data} icon={icon} {...deleteProps} />
      ) : null}
      {childrenLocation == "end" ? children : null}
      {endChildren}
    </FlexBox>
  );
}
